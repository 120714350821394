.App {
  text-align: center;
}

.but-styles {
  
    position: relative;
    appearance: none;
    max-width: 100%;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: middle;
    box-sizing: border-box;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    font-weight: 500;
    z-index: 1;
    height: 44px;
    width: 150px;
    color: white;
    font-size: 20px;
    margin: 32px 0px 0px;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    outline: 0px;
    text-decoration: none;
    border-width: 0px;
    border-style: initial;
    border-image: initial;
    padding: 0px;
    border-color: rgb(229, 231, 235);
    border-radius: 28px;
    background: rgb(0, 0, 0);

}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text{
  color: #111927
}

